import _ from 'lodash';
import React from 'react';
import Field from '../utils/Field';
import { SEARCH_TAB } from '../constants';

const INITIAL_STATE = { 
  searchForm: {
    documentTypes: null,
    types: [],
    fields: [],
    selected: null,
    form: null
  },
  uploadForm: {
    documentTypes: null,
    types: [],
    fields: [],
    selected: null,
    form: null
  }
}

const types = (state = INITIAL_STATE, action) => {
  let newState = _.cloneDeep(state);
  switch (action.type) {
    case "CHANGE_TAB":
      if (action.tabId === SEARCH_TAB){
        updateSearchForm(state.searchForm.selected, newState); 
      }
      else{
        updateUploadForm(state.uploadForm.selected, newState);
      }
      return newState;
    case "CHANGE_LANG":
      updateSearchForm(state.searchForm.selected, newState);
      updateUploadForm(state.uploadForm.selected, newState);
      return newState;
    case "CHANGE_DOCTYPE":
      updateSearchForm(action.payload, newState);
      return newState;
    case "CHANGE_UPLOADTYPE":
      updateUploadForm(action.payload, newState);
      return newState;
    case "LOAD_SEARCH_TYPES_SUCCESS":
      updateSearchState(action.data, newState);
      return newState;
    case "LOAD_CREATE_TYPES_SUCCESS":
      updateUploadState(action.data, newState);
      return newState;
    case "LOAD_TYPES_FAILURE":
      console.log("Failure to load types");
      return state;
    default:
      return state
  }
}


const updateSearchState = (config, state) => {
  const documentTypes = new Map();
  const field = new Field();

  for(let typeKey in config){
    const type = config[typeKey];
    if (state.searchForm.selected == null) state.searchForm.selected = typeKey;
    const attrs = [];
      for (let attrKey in type.fields){
        const attr = type.fields[attrKey];
        if (attr.search){
          attrs.push(attr);
        }
      }
    documentTypes[typeKey] = attrs;
  }
  
  state.searchForm.documentTypes = documentTypes;

  state.searchForm.fields = documentTypes[state.searchForm.selected];
  if (!state.searchForm.fields) state.searchForm.fields = [];
  state.searchForm.types = [];
  for(let typeKey in config) { 
    state.searchForm.types.push(<option key={typeKey}>{typeKey}</option>); 
  }
  state.searchForm.form = state.searchForm.fields.map((item, index) => {
    return field.getForm(item)
  });
}

const updateUploadState = (config, state) => {
  const documentTypes = new Map();
  const field = new Field();

  for(let typeKey in config){
    const type = config[typeKey];
    if (state.uploadForm.selected == null) state.uploadForm.selected = typeKey;
    const attrs = [];
      for (let attrKey in type.fields){
        const attr = type.fields[attrKey];
        //if (attr.update){
          attrs.push(attr);
        //}
      }
    documentTypes[typeKey] = attrs;
  }
  
  state.uploadForm.documentTypes = documentTypes;

  state.uploadForm.fields = documentTypes[state.uploadForm.selected];
  if (!state.uploadForm.fields) state.uploadForm.fields = [];
  state.uploadForm.types = [];
  for(let typeKey in config) { 
    state.uploadForm.types.push(<option key={typeKey}>{typeKey}</option>); 
  }
  state.uploadForm.form = state.uploadForm.fields.map((item, index) => {
    return field.getForm(item)
  });
}

const updateSearchForm = (selected, state) => {
    const field = new Field();
    const keys = state.searchForm.documentTypes;
    state.searchForm.selected = selected;
    if (state.searchForm.documentTypes && state.searchForm.documentTypes[selected])
      state.searchForm.fields = state.searchForm.documentTypes[selected];
    if (!state.searchForm.fields) 
      state.searchForm.fields = [];
    state.searchForm.form = state.searchForm.fields.map((item) => {return field.getForm(item, true)});
}

const updateUploadForm = (selected, state) => {
    const field = new Field();
    state.uploadForm.selected = selected;
    if (state.uploadForm.documentTypes && state.uploadForm.documentTypes[selected])
      state.uploadForm.fields = state.uploadForm.documentTypes[selected];
    if (!state.uploadForm.fields) state.uploadForm.fields = [];
    state.uploadForm.form = state.uploadForm.fields.map((item) => {return field.getForm(item, false)});
}

export default types;