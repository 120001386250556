import React from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';


const hrStyle = {
    color: 'gray',
    backgroundColor: 'gray',
    height: .5,
    borderColor: 'gray'
};

const SearchForm = (state) => {

    const getValueById = (id) => document.getElementById(id).value;
    const getByName = (id) => document.getElementsByName(id);
    
    const handleChange = v => { state.changeDocumentType(v); }

    const onFormSubmit = () => {
        if (!state || !state.fields) {
            console.log("The are no form fields. Cannot perform search");
            return;
        }
        const docType = state.selected;
        const attributes = {};
        for (var i = 0; i < state.fields.length; i++) {
            const key = state.fields[i]['name'];
            const elem = getByName(key);
            if (state.fields[i].type == "boolean" && elem.length>0) {
                const value = elem[0].value;
                value = (value === "on");
            }
            else if (state.fields[i].type == "date" && elem.length>1) {
                const from = elem[0].id == "from"? elem[0].value : elem[1].value;
                const to = elem[0].id == "to"? elem[0].value : elem[1].value;
                if (from && to) attributes[key] = "/btw/" + from + "," + to
                else if (from) attributes[key] = "/gte/" + from;
                else if (to) attributes[key] = "/lte/" + to;
            }
            else if (elem.length>0 && elem[0].value) {
                const value = elem[0].value;
                attributes[key] = value;
            }
        }       

        state.searchDocuments(docType, attributes);
    }
    
    return (
        <div style={{ textAlign: 'center' }}>
            < Form >
            
            <Button className="mb-3" color='primary' onClick={onFormSubmit}>{state.strings['Search']}</Button>
                <FormGroup>
                    <h4>{state.strings['Documenttypes']}</h4>
                    <Input type="select" name="select" id="documentTypes" value={state.selected} onChange={(e) => { handleChange(e.target.value); }}>
                        {state.types}
                    </Input>
                </FormGroup>

                <hr style={hrStyle} />
                <h4>Metadata</h4>
                {state.form}
                <hr style={hrStyle} />        
            </Form >
        </div >
    )
}
export default SearchForm;