import axios from 'axios';
import UserMessage from './UserMessage';
import {
  CONTENT_CHUNK_SIZE, LOCAL_SERVER, LOCAL_LOGIN_SERVER, DEV_SERVER,
  PROD_SERVER, DEV_LOGIN_SERVER, PROD_LOGIN_SERVER
} from '../constants';


export default class HttpClient {

  messenger = new UserMessage();

  refreshTokens = async () => {
    const result = await axios.post(this.getLoginServerUrl() + "/refresh", new URLSearchParams(),
      { headers: { 'Authorization': "Basic " + sessionStorage.getItem('refresh_token') } });
    sessionStorage.setItem('access_token', result.data.tokens.access_token);
    sessionStorage.setItem('refresh_token', result.data.tokens.refresh_token);
  }

  getServerUrl = () => {
    /*
        let server = PROD_SERVER;
        if (window.location.href.includes('localhost'))
          server = localStorage.getItem('mmserver');
        else if (window.location.href.includes('dev'))
          server = DEV_SERVER;
        */
    return PROD_SERVER; //DEV_SERVER; //LOCAL_SERVER; 
  }

  getLoginServerUrl = () => {
    /*
    let loginserver = PROD_LOGIN_SERVER;
    if (window.location.href.includes('localhost'))
      loginserver = localStorage.getItem('mmloginserver');
    else if (window.location.href.includes('dev'))
      loginserver = DEV_LOGIN_SERVER;
   return loginserver;
   */
    return PROD_LOGIN_SERVER; //DEV_LOGIN_SERVER; //LOCAL_LOGIN_SERVER
  }

  sendGet = async (url, params = {}, resend = true) => {
    try {
      const result = await axios.get(url,
        {
          headers: { 'Authorization': "Basic " + sessionStorage.getItem('access_token') },
          params: params
        });
      return result.data;
    }
    catch (error) {
      if (error.response.status == 401 && resend == true) {
        await this.refreshTokens();
        return this.sendGet(url, params, false);
      }
      else {
        throw error;
        //return {};
      }
    }
  }

  sendPost = async (url, formData = {}, resend = true) => {
    try {
      const header = { headers: { 'Authorization': "Bearer " + sessionStorage.getItem('access_token') } };
      const result = await axios.post(url, formData, header);
      return result.data
    }
    catch (error) {
      if (error.response.status == 401 && resend == true) {
        await this.refreshTokens();
        return this.sendPost(url, formData, false);
      }
      else {
        throw error;
      }
    }
  }

  sendPut = async (url, formData, resend = true) => {
    try {
      const header = { headers: { 'Authorization': "Bearer " + sessionStorage.getItem('access_token') } };
      const result = await axios.put(url, formData, header);
      return result.data;
    }
    catch (error) {
      if (error.response.status == 401 && resend == true) {
        await this.refreshTokens();
        return this.sendPut(url, formData, false);
      }
    }
  }

  sendDelete = async (url, resend = true) => {
    try {
      const header = { headers: { 'Authorization': "Bearer " + sessionStorage.getItem('access_token') } };
      const result = await axios.delete(url, header);
      return result.data;
    }
    catch (error) {
      if (error.response.status == 401 && resend == true) {
        this.refreshTokens();
        this.sendDelete(url, false);
      }
      else {
        throw error;
      }
    }
  }

  sendCode = async (email) => {
    const formData = new URLSearchParams();
    formData.append("email", email);
    const result = await axios.post(this.getLoginServerUrl() + "/generate", formData);
    console.log("sendCode result=" + JSON.stringify(result));
  }

  check = async (email, type) => {
    const formData = new URLSearchParams();
    formData.append("email", email);
    formData.append("type", type);
    const result = await axios.post(this.getLoginServerUrl() + "/check", formData);
    console.log("check result=" + result);
  }

  checkPwd = async (email, password) => {
    const formData = new URLSearchParams();
    formData.append("email", email);
    formData.append("password", password);
    try {
      const result = await axios.post(this.getLoginServerUrl() + "/checkpwd", formData);
      return 200;
    }
    catch (error) {
      console.error(JSON.stringify(error));
      if (error.response && error.response.status)
        return error.response.status;
      else
        return 500;
    }
  }

  login = async (email, password, code) => {
    const formData = new URLSearchParams();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("code", code);
    formData.append("type", "password");
    const result = await axios.post(this.getLoginServerUrl() + "/login", formData);
    sessionStorage.setItem('access_token', result.data.tokens.access_token);
    sessionStorage.setItem('refresh_token', result.data.tokens.refresh_token);
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('company', result.data.group);
  }

  login_freja = async () => {
    const url = this.getLoginServerUrl() + "/login?type=freja"
    const result = await axios.get(url);
  }

  login_ssn = async (ssn) => {
    const formData = new URLSearchParams();
    formData.append("ssn", ssn)
    formData.append("type", "bankid");
    const result = await axios.post(this.getLoginServerUrl() + "/login", formData);
    sessionStorage.setItem('access_token', result.data.tokens.access_token);
    sessionStorage.setItem('refresh_token', result.data.tokens.refresh_token);
    sessionStorage.setItem('company', result.data.group);
  }

  login_email = async (email) => {
    const formData = new URLSearchParams();
    formData.append("email", email)
    formData.append("type", "freja");
    let result = await axios.post(this.getLoginServerUrl() + "/login", formData);
    do {
      await this.delay(1);
      const header = { headers: { 'Authorization': "Bearer " + result.data.token } };
      result = await axios.post(this.getLoginServerUrl() + "/poll", formData, header);
    }
    while (result.status == 202)
    sessionStorage.setItem('access_token', result.data.tokens.access_token);
    sessionStorage.setItem('refresh_token', result.data.tokens.refresh_token);
    sessionStorage.setItem('company', result.data.group);
  }

  logout = async (ssn) => {
    const formData = new URLSearchParams();
    const result = this.sendPost(this.getLoginServerUrl() + "/logout");
    console.log(result);
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('company');
  }

  list = async (doctype, attributes) => {
    const filter = {};
    const sort = {};
    const range = {};
    if (doctype) filter['type'] = doctype;
    for (const key in attributes) {
      filter[key] = attributes[key];
    }
    const url = this.getServerUrl() + '/documents?audit';
    const params = { filter: filter, sort: sort, range: range };
    const result = await this.sendGet(url, params);
    return result;
  }

  uploadSingle = async (data, file, filelist) => {
    const formData = new FormData();
    if (data.fields) {
      Object.entries(data.fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
    }
    formData.append("file", file);
    await axios({
      method: data.method,
      url: data.url,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    
    if (filelist) {
      filelist[file.index].progress = 100;
      filelist[file.index].sent = true;
    }
  }

  uploadMultiple = async (data, file, filelist, callback) => {
    try{
      const uploadPromises = [];
      const percentPerChunk = CONTENT_CHUNK_SIZE*100/file.size;
      console.log("Percent per chunk:" + percentPerChunk)

      for (let i = 0; i < data.urls.length; i++) {
        const start = i * CONTENT_CHUNK_SIZE;
        const end = Math.min(start + CONTENT_CHUNK_SIZE, file.size);
        const chunk = file.slice(start, end);
        const presignedUrl = data.urls[i];
  
        filelist[file.index].progress = 1;
        callback(filelist);

        uploadPromises.push(
          axios.put(presignedUrl, chunk, {
            headers: {
              "Content-Type": file.type,
            }, 
            onUploadProgress: progressEvent => {
              if (progressEvent.total == progressEvent.loaded) {
                let progress = Math.round(filelist[file.index].progress + percentPerChunk);
                if (progress > 100) progress = 100; 
                filelist[file.index].progress = progress;
                callback(filelist);
                console.log("Part loaded, total%: " + filelist[file.index].progress);
              }
            }
          })
        );
      }

      const uploadResponses = await Promise.all(uploadPromises);

      if (filelist) {
        filelist[file.index].progress = 100;
        filelist[file.index].sent = true;
      }

      const etags = [];

      for (let i = 0; i < uploadResponses.length; i++) {
        if (uploadResponses[i].status != 200) {
          await axios.post(this.getServerUrl() + "/" + data.id + "/abort" + data.uploadId);
          throw { "message": "Failed multipart upload 2" };
        }
        etags.push(uploadResponses[i].headers.etag);
      };

      const url = this.getServerUrl() + "/document/" + data.id + "/complete/" + data.uploadId;
      const formData = new URLSearchParams();
      formData.append("etags", etags);
      await this.sendPost(url, formData);
    }
    catch(error){
        console.log("Failed to complete:" + JSON.stringify(error));
        await this.sendPost(this.getServerUrl() + "/document/" + data.id + "/abort/" + data.uploadId);
        throw { "message": "Failed to complete multipart upload" };
    }
  }


  upload = async (metadata, file, docid, filelist, callback) => {

    let url = this.getServerUrl() + "/document";
    if (docid) url += "/" + docid;
    let nChunks = Math.ceil(file.size / CONTENT_CHUNK_SIZE);

    const formData = new URLSearchParams();
    formData.append("doctype", metadata.type);
    formData.append("mimetype", file.type);
    formData.append("filename", file.name);
    formData.append("noParts", nChunks);
    formData.append("metadata", JSON.stringify(metadata));

    const data = await this.sendPost(url, formData);

    return new Promise(async (resolve, reject) => {
      try {
        if (data.url) await this.uploadSingle(data, file, filelist);
        else if (data.urls) await this.uploadMultiple(data, file, filelist, callback);
        else { reject({ message: "Failed to get url to content storage" }); }
        resolve();
      }
      catch (error) {
        if (!error.response && data.storage === 'minio')
          resolve();
        else {
          reject(error);
        }
      }
    });
  }

  download = async (id, version, isAttachment, mimetype, pdf) => {
    try {
      console.log("Download for id:" + id + isAttachment + mimetype);
      if (!isAttachment) isAttachment = false;
      if (!mimetype) mimetype = "application/pdf";
      let url = this.getServerUrl() + "/document/" + id + "?isAttachment=" + isAttachment;
      if (pdf) url += '&pdf=true';
      if (version < 0) url += "&version=" + version;
      const result = await this.sendGet(url);
      if (!result || !result.url) return "file://no_content.txt";
      console.log("Returning url: " + result.url);
      return result.url;
    }
    catch (error) {
      let msg = "Kunde inte hämta filen";
      if (error && error.response && error.response.data && error.response.data.message) {
        msg = error.response.data.message;
        //this.messenger.alert(error.response.data.message)
      };
      throw (msg);
      //return "error://" + msg;
      //else
      //this.messenger.alert("Failed to download. Reason:" + JSON.stringify(error));
      //throw error;
    }
  }

  audit = async (id) => {
    const url = this.getServerUrl() + "/document/" + id + "?type=audit";
    const result = this.sendGet(url);
    return result;
  }

  types = async action => {
    const url = this.getServerUrl() + '/types?action=' + action;
    const result = await this.sendGet(url);
    return result;
  }

  count = async () => {
    const url = this.getServerUrl() + '/count';
    const result = await this.sendGet(url);
    return result;
  }

  ping = async () => {
    const url = this.getServerUrl() + '/count';
    const result = await this.sendGet(url);
    return result;
  }

  reset = async (email) => {
    const url = this.getServerUrl() + "/reset";
    const formData = new URLSearchParams();
    formData.append("email", email);
    const result = await this.sendPost(url, formData);
    return result;
  }

  confirm = async (email, password, code) => {
    const formData = new URLSearchParams();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("code", code);
    const url = this.getServerUrl() + "/confirm";
    const result = await this.sendPost(url, formData);
    return result;
  }

  update = async (id, attr, value, success) => {
    const data = {}
    data['metadata'] = '{"' + attr + '":"' + value + '"}';
    const url = this.getServerUrl() + '/document/' + id;
    try {
      await this.sendPut(url, data);
      success();
    }
    catch (error) {
      if (error && error.response && error.response.data && error.response.data.message)
        this.messenger.alert(error.response.data.message)
      else
        this.messenger.alert("Failed to update. Reason:" + JSON.stringify(error));
      throw error
    }
  }

  delete = async (id, success, isFullDelete = true) => {
    let url = this.getServerUrl();
    if (isFullDelete) {
      url += '/document/';
    }
    else {
      url += '/version/';
    }
    url += id;
    try {
      await this.sendDelete(url);
      success();
    }
    catch (error) {
      if (error && error.response && error.response.data && error.response.data.message)
        alert(error.response.data.message)
      else
        alert("Kunde ej radera. Orsak:" + JSON.stringify(error));
    }
  }

  exportAsDip = async (ids) => {
    const url = this.getServerUrl() + '/dip';
    const formData = new URLSearchParams();
    formData.append("ids", JSON.stringify(ids));
    const header = {
      headers: {
        'Authorization': "Bearer " + sessionStorage.getItem('access_token'),
        'Content-Type': 'multipart/form-data'
      }
    };
    const result = await axios.post(url, formData, header);
    return result.data
  }

  delay = (seconds) => {
    return new Promise(resolve => {
      setTimeout(resolve, seconds * 1000);
    });
  }
}
